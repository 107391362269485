@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Monsterrat", sans-serif;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

body::-webkit-scrollbar {
  display: none;
}
